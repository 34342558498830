<template>
  <div
    class="input input_visible_label"
    :class="{ 'input_error': !!errorMessage }"
  >
    <label
      :for="name"
      class="input__label"
      :class="labelClass"
    >
      {{ labelText }}
    </label>
    <div class="input__error" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <input
      :name="name"
      :id="name"
      class="input__field"
      :class="inputClass"
      :type="type"
      :value="inputValue"
      :placeholder="placeholder"
      :readonly="readonly"
      @input="inputText"
      @blur="handleBlur"
    />
  </div>
</template>

<script>
import { useField } from 'vee-validate'

export default {
  name: 'InputText',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      required: true
    },
    labelClass: {
      type: String,
      default: ''
    },
    inputClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta
    } = useField(props.name, undefined, {
      initialValue: props.value
    })

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta
    }
  },
  methods: {
    inputText (value) {
      this.$emit('setValue', value.target.value)
      this.handleChange(value)
    }
  },
  watch: {
    value (value) {
      this.inputValue = value
    }
  }
}
</script>
