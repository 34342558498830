<template>
  <div class="fp-wrapper" v-if="!successRequest">
    <div class="modal-window">
      <div class="content content_form">
        <form-header />
        <h2 class="h2 content__h2">Восстановить пароль</h2>

        <section class="content__section">
          <p class="content__section-text">Введите адрес электронной почты, указанный при регистрации.</p>
        </section>
        <form-observer :validation-schema="restoreSchema" @submit="onSubmit">
          <input-text
            name="email"
            type="email"
            label-text="Электронная почта"
            placeholder="Введите e-mail"
          />
          <div class="content__section">
            <button class="button">Восстановить пароль</button>
          </div>
        </form-observer>
      </div>
    </div>
  </div>

  <message-wrapper v-else>
    <template v-slot:title>Проверьте почту</template>
    <template v-slot:text>На Вашу электронную почту отправлено письмо с&#160;инструкцией для&#160;восстановления пароля.</template>
    <template v-slot:description>Если не&#160;можете его найти, посмотрите письмо в&#160;папке Спам.</template>
  </message-wrapper>
</template>

<script>
import { Form as FormObserver } from 'vee-validate'
import InputText from '@/components/common/InputText'
import { restoreSchema } from '@/validation'
import FormHeader from '@/components/common/FormHeader'
import MessageWrapper from '@/components/common/MessageWrapper'

export default {
  name: 'RestorePassword',
  components: {
    FormObserver,
    InputText,
    FormHeader,
    MessageWrapper
  },
  data () {
    return {
      restoreSchema,
      successRequest: false
    }
  },
  methods: {
    onSubmit (values) {
      this.$axios.post(this.getPathRequest('reset_password_request'), values)
        .then(response => {
          if (response.data.status) {
            this.successRequest = true
          }
        })
        .catch(error => {
          this.openModal('ErrorModal', {
            title: 'Ошибка восстановления пароля',
            text: error.response.data.message
              ? error.response.data.message
              : 'Во время восстановления пароля произошла ошибка. Пожалуйста, попробуйте снова или обратитесь к нам в тех. поддержку.'
          })
        })
    }
  }
}
</script>
