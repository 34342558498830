<template>
  <div class="content__header">
    <div class="content__logo">
      <a href="/" class="logo content__logo" target="_blank">
        <svg class="icon icon-logo_medium">
          <use xlink:href="#icon-logo_medium"></use>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormHeader'
}
</script>
