<template>
  <div class="fp-wrapper">
    <div class="modal-window">
      <div class="content content_form">
        <form-header />
        <h2 class="h2 mb-5">
          <slot name="title"></slot>
        </h2>
        <section class="content__section">
          <p class="content__section-text">
            <slot name="text"></slot>
          </p>
        </section>
        <section v-if="hasSlot('description')" class="content__section mt-5">
          <p class="small light">
            <slot name="description"></slot>
          </p>
        </section>
        <section v-if="hasSlot('footer')" class="content__section mt-5">
          <slot name="footer"></slot>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import FormHeader from '@/components/common/FormHeader'

export default {
  name: 'MessageWrapper',
  components: {
    FormHeader
  },
  methods: {
    hasSlot (name = 'default') {
      return !!this.$slots[name]
    }
  }
}
</script>
